.ventas-ejecutivo {
  table-layout: fixed !important; // Fuerza un diseño de tabla fijo para respetar los anchos
  width: 80% !important; // Cambia el ancho al 80% del contenedor padre
  margin-top: 50px !important; // Añade un margin-top de 50px
  margin-left: auto !important; // Centra la tabla horizontalmente
  margin-right: auto !important; // Centra la tabla horizontalmente
  display: flex; // Use flexbox for the entire container
  flex-direction: column; // Stack elements vertically
  align-items: center; // Center all children horizontally

  // Container for the logo
  .logo-container {
    text-align: center; // Centers the logo horizontally (redundant with flex, but kept for fallback)
    margin-bottom: 1rem; // Adds spacing below the logo (equivalent to Bootstrap's mb-4)
    width: 100%; // Ensure the logo container takes the full width of the parent
    display: flex; // Use flexbox to ensure proper centering
    justify-content: center; // Horizontally center the logo
  }

  // Styling for the logo image
  .logo {
    max-width: 100px; // Maintains the smaller size as requested
    height: auto; // Maintains aspect ratio
  }

  // Ensure the row of components (select and button) is centered
  .row {
    width: 100%; // Ensure the row takes the full width of the parent
    display: flex; // Use flexbox to control alignment
    justify-content: center; // Center the row's children
    flex-wrap: wrap; // Allow wrapping if needed
  }

  // Center the columns within the row
  .col-md-6 {
    display: flex;
    justify-content: center; // Center the content within each column
    align-items: center; // Vertically center the content
  }
}

.btn-dark-silver {
  background-color: #1a1a1a; /* Gris plateado/negro muy oscuro */
  color: #ffffff; /* Letras blancas */
  border-color: #1a1a1a; /* Borde del mismo color que el fondo */
}

.btn-dark-silver:hover {
  background-color: #333333; /* Un tono ligeramente más claro para el efecto hover */
  border-color: #333333; /* Borde del mismo color que el fondo en hover */
}

.custom-select-width {
  width: auto;
  max-width: 150px; // Ajusta este valor según necesites
  margin: 0 auto; // Esto centrará el select dentro de su div
}

.custom-button-width {
  width: auto;
  max-width: 150px; // Ajusta este valor para que coincida con el select
  margin: 0 auto; // Esto centrará el botón dentro de su div
}