.inventario {
    background-color: rgb(36, 36, 36);
    border-radius: 10px;
    border: .5px darkslategray solid;
    color: white;
}
.tituloinventario {
    color: white;
    font-size: 35px;
    margin-top: 1rem;
    text-align: center;
}
.subtitulo {
    color: white;
    font-size: 25px;
    margin-top: 1rem;
    text-align: center;
}
.checkbox {
    display: flex;
    align-items: center; /* Alinea verticalmente el checkbox y el texto */
    font-size: 13px; 
    margin-bottom: .7rem;
    margin-left: 1rem;
}

.checkbox span {
    line-height: 1; /* Evita que el line-height del texto desalinee el contenido */
}