.container-ventas-detalle {
  max-width: 90%; // Limita el ancho total al 90% del contenedor padre
  margin: 0 auto; // Centra horizontalmente el contenedor
  padding: 20px; // Opcional: añade padding para mejor apariencia
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center; // Centra horizontalmente los elementos hijos (logo y botón)
  width: 100%; // Asegura que ocupe el mismo ancho que el contenedor padre
  max-width: 100%; // Evita que se desborde del contenedor
}

.logo-centered {
  max-width: 100px; // Tamaño ajustado del logo
  height: auto; // Mantiene la proporción
}

/* More specific selector to override any inherited styles */
.container-ventas-detalle .table td,
.container-ventas-detalle .table th {
  white-space: normal !important; // Permite salto de línea
  overflow: visible !important; // Evita truncamiento o ellipsis
  text-overflow: unset !important; // Explicitly unset any text-overflow
  font-family: "Trebuchet MS", "Trebuchet", sans-serif !important; // Establece la tipografía
  padding: 8px !important; // Match padding from reference (table-container-a)
  word-wrap: break-word !important; // Legacy alias for overflow-wrap, ensures wrapping
  overflow-wrap: break-word !important; // Modern property for wrapping
  line-height: 1.2 !important; // Match reference line-height
}

/* Specific override for table headers */
.container-ventas-detalle .table th {
  background-color: #343a40 !important; // Color oscuro de encabezados
  color: white !important; // Texto blanco
  text-align: center !important; // Centra el texto
  min-height: 44px !important; // Ensure enough space for two lines
  vertical-align: middle !important; // Centra verticalmente
  text-overflow: unset !important; // Reinforce no truncation
  overflow: visible !important; // Reinforce no truncation
}

.table {
  table-layout: fixed !important; // Asegura que la tabla respete los anchos definidos
  width: 100% !important; // Ocupa el 100% del ancho del contenedor
  border-collapse: collapse !important; // Elimina espacios entre celdas
}

.table tbody tr:nth-child(odd) {
  background-color: #f8f9fa !important; // Filas impares
}

.table tbody tr:hover {
  background-color: #e9ecef !important; // Efecto hover
}

.table td.text-start {
  text-align: left !important; // Alineación izquierda para CLIENTE
}

.bg-verde {
  background-color: green;
}

.bg-rojo {
  background-color: red;
}

.bg-azul {
  background-color: blue;
}

.bg-amarillo {
  background-color: yellow;
}

.recharts-legend-wrapper {
  display: none !important;
}

.active-filter {
  background-color: lightblue !important; // Azul claro
  border-color: #007bff !important; // Borde azul
}

.row {
  width: 100%; // Ocupa el 100% del contenedor
  margin-left: 0; // Elimina margen izquierdo de Bootstrap
  margin-right: 0; // Elimina margen derecho de Bootstrap
}

.d-flex {
  width: 100%; // Ocupa el 100% del contenedor
}

.details-container {
  padding: 15px;

  h5 {
    margin-bottom: 15px;
    color: #343a40;
  }

  p {
    margin-bottom: 10px;
    line-height: 1.4;

    strong {
      color: #495057;
    }
  }

  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #343a40;
  }
}

.details-container {
  padding: 15px;

  h5 {
    margin-bottom: 15px;
    color: #343a40;
  }

  p {
    margin-bottom: 10px;
    line-height: 1.4;

    strong {
      color: #495057;
    }
  }

  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #343a40;
  }

  .modal-header {
    padding: 10px 15px !important;
  }
  
  .modal-header .d-flex {
    width: 100% !important;
    align-items: center !important;
  }
  
  .modal-header .row {
    width: 100% !important;
    margin: 0 !important; // Elimina márgenes de Bootstrap
    padding-right: 30px !important; // Espacio para el botón de cerrar
  }
  
  .modal-header .col {
    padding: 0 !important; // Elimina padding interno de las columnas
  }
  
  .modal-header .col.text-start {
    text-align: left !important;
  }
  
  .modal-header .col-auto.text-end {
    text-align: right !important;
  }
  
  .status-indicator {
    display: inline-block;
  }

  // Estilos para el título clickable
  strong {
    &.collapsible-title {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #343a40;
      transition: color 0.2s ease;

      &:hover {
        color: #007bff; // Color azul al pasar el mouse
      }

      span {
        margin-left: 15px;
        font-size: 12px;
      }
    }
  }

  // Estilos para la lista colapsable
  .collapse {
    ul {
      margin-top: 20px;
      padding-left: 60px;
    }
  }
}