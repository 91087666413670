.container-ventas-region-reciente {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;

  .table {
    width: 100% !important; // Asegura que la tabla ocupe todo el ancho del contenedor
    table-layout: fixed; // Usa un diseño fijo para respetar los anchos de las columnas

    th,
    td {
      white-space: nowrap; // Evita el ajuste de línea en las celdas
      overflow: hidden; // Maneja el desbordamiento si el texto es demasiado largo
      text-overflow: ellipsis; // Agrega puntos suspensivos para texto desbordado (opcional)
    }

    // Define los anchos de las columnas según los porcentajes solicitados
    th:nth-child(1),
    td:nth-child(1) {
      width: 5%; // PAÍS
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 20%; // CLIENTE
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 5%; // CIERRE (MES CIERRE)
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 5%; // FACTURA (MES)
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 8%; // TICKET
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 8%; // STATUS
    }

    th:nth-child(7),
    td:nth-child(7) {
      width: 5%; // TIPO
    }

    th:nth-child(8),
    td:nth-child(8) {
      width: 20%; // CATEGORÍA
    }

    th:nth-child(9),
    td:nth-child(9) {
      width: 16%; // INTERMEDIARIO
    }

    th:nth-child(10),
    td:nth-child(10) {
      width: 8%; // EJECUTIVO
    }

    // Asegura que los encabezados y celdas estén alineados correctamente
    th {
      text-align: center;
      vertical-align: middle;
    }

    td {
      text-align: center;
      vertical-align: middle;
    }

    // Ajusta la alineación del texto para columnas específicas según el componente
    td:nth-child(2) { // CLIENTE
      text-align: start; // Alineación a la izquierda
    }

    td:nth-child(5) { // TICKET
      text-align: end; // Alineación a la derecha
    }

    // Mejora la responsividad (opcional)
    @media (max-width: 768px) {
      th,
      td {
        font-size: 12px; // Reduce el tamaño de la fuente en pantallas pequeñas
      }
    }
  }
}