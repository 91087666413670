.table-responsive {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin: 0 auto; /* Centra la tabla horizontalmente */
  /* Opcional: Define un ancho máximo si quieres limitar el tamaño */
  /* max-width: 1200px; /* Descomenta y ajusta si deseas un ancho fijo */
}

.table-fixed {
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
}

.table-fixed th,
.table-fixed td {
  padding: 0.5rem; /* Añadimos un padding consistente */
}

/* Estilos específicos para los encabezados (th) con mayor especificidad */
.table-fixed thead th {
  text-align: center; /* Centramos el texto en los encabezados */
  background-color: #343a40; /* Color oscuro para consistencia con la imagen */
  color: white;
  white-space: normal !important; /* Forzamos que el texto se divida en varias líneas */
  word-break: break-word !important; /* Forzamos romper palabras si es necesario */
  overflow: visible !important; /* Aseguramos que el texto no se trunque */
  text-overflow: initial !important; /* Eliminamos los puntos suspensivos */
  line-height: 1.2; /* Ajusta la altura de línea para mejor legibilidad */
  min-height: 3em; /* Altura mínima para permitir dos renglones */
  font-size: 0.9rem; /* Tamaño de fuente reducido */
}

/* Estilos específicos para las celdas de datos (td) */
.table-fixed tbody td {
  white-space: nowrap; /* Mantenemos nowrap en las celdas de datos */
  overflow: hidden; /* Trunca el texto si excede el ancho */
  text-overflow: ellipsis; /* Muestra puntos suspensivos si se trunca */
  font-size: 0.85rem; /* Tamaño de fuente reducido */
}

/* Estilo específico para el tfoot */
.table-fixed tfoot td {
  font-weight: bold;
  background-color: #343a40; /* Color oscuro para totales */
  color: white;
  white-space: normal; /* Permite que los totales se dividan si es necesario */
  word-break: break-word;
  font-size: 0.85rem; /* Tamaño de fuente reducido */
}

/* Estilo para la columna "COBRADA" */
.table-fixed td:nth-child(12) { /* Columna "COBRADA" */
  background-color: #198754; /* Verde para cobrado */
  color: white;
}