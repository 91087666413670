.body-ficha {
  background-color: black;
  color: #2CE67C; /* Color verde para el texto */
  font-family: "Trebuchet MS", sans-serif;
}

.ficha-edificios {
  width: 85%;
  min-height: 750px;
  border: 2px solid white;
  border-radius: 2%;
  margin-bottom: 2%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-image: 
    linear-gradient(45deg, 
      rgba(255, 255, 255, 0.1) 10%, 
      transparent 10%, 
      transparent 40%, 
      rgba(255, 255, 255, 0.1) 40%, 
      rgba(255, 255, 255, 0.1) 50%, 
      transparent 50%, 
      transparent 90%, 
      rgba(255, 255, 255, 0.1) 90%); /* Patrón de líneas más delgadas */
  background-size: 7px 7px; /* Tamaño del patrón */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  padding: 10px; /* Aumentar el espacio interno */
}

.edificios-imagen-edificio {
  border-radius: 3%;
  border: 2px solid white; /* Agrega un borde blanco de 1px */
  bottom: 5%;
  max-height: 90%;
  object-fit: contain; /* Mantiene la proporción de la imagen */
  position: absolute;
  right: 1.5%;
  z-index: 2;
}

.nombre-edificio {
  background-color: #2CE67C;
  color: black;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 5vw;
  font-weight: bold;
  padding-left: 2%;
  text-align: left;
  width: 100%;
}

.div-ubicacion {
  align-items: center;  // Centra los elementos hijos horizontalmente
  color: white;
  display: flex;
  flex-direction: column;  // Asegura que los elementos se dispongan en columna
  gap: 10px;  // Espacio uniforme entre los elementos (ícono, texto y bajada)
  left: 2%;
  position: absolute;
  top: 27%; 
  width: 17%;
}

.ubicacion-icono {
  color: #2CE67C;
  width: 75px;  // También controla el tamaño con width
  height: 75px;  // Controla el tamaño con height
  position: relative;
}

.ubicacion-texto {
  font-size: 1.4rem;
  position: relative;
  text-align: center;  
  width: 100%; /* Asegura que el texto ocupe todo el ancho del contenedor */
  word-wrap: break-word; // Centra el contenido del texto
}

// .ubicacion-bajada {
//   font-size: 1.2rem;
//   position: relative;
//   text-align: center;  // Centra el contenido de la bajada
//   width: 100%;  // Asegura que la bajada ocupe todo el ancho disponible para centrarse
// }

.div-ascensores {
  align-items: center;  // Centra los elementos hijos horizontalmente
  color: white;
  display: flex;
  flex-direction: column;  // Asegura que los elementos se dispongan en columna
  gap: 10px;  // Espacio uniforme entre los elementos (ícono, texto y bajada)
  left: 20%;
  position: absolute;
  top: 27%; 
  width: 15%;
}

.ascensores-icono {
  color: #2CE67C;
  width: 75px;  // También controla el tamaño con width
  height: 75px;  // Controla el tamaño con height
  position: relative;
}

.ascensores-texto {
  font-size: 1.4rem;
  position: relative;
  text-align: center;  // Centra el contenido del texto
}

.ascensores-bajada {
  font-size: 1.2rem;
  position: relative;
  text-align: center;  // Centra el contenido de la bajada
  width: 100%;  // Asegura que la bajada ocupe todo el ancho disponible para centrarse
}

.div-totems {
  align-items: center;  // Centra los elementos hijos horizontalmente
  color: white;
  display: flex;
  flex-direction: column;  // Asegura que los elementos se dispongan en columna
  gap: 10px;  // Espacio uniforme entre los elementos (ícono, texto y bajada)
  left: 35%;
  position: absolute;
  top: 27%; 
  width: 15%;
}

.totems-icono {
  color: #2CE67C;
  width: 75px;  // También controla el tamaño con width
  height: 75px;  // Controla el tamaño con height
  position: relative;
}

.totems-texto {
  font-size: 1.4rem;
  position: relative;
  text-align: center;  // Centra el contenido del texto
}

.totems-bajada {
  font-size: 1.2rem;
  position: relative;
  text-align: center;  // Centra el contenido de la bajada
  width: 100%;  // Asegura que la bajada ocupe todo el ancho disponible para centrarse
}

.div-espera {
  align-items: center;  // Centra los elementos hijos horizontalmente
  color: white;
  display: flex;
  flex-direction: column;  // Asegura que los elementos se dispongan en columna
  gap: 10px;  // Espacio uniforme entre los elementos (ícono, texto y bajada)
  left: 50%;
  position: absolute;
  top: 27%; 
  width: 15%;
}

.espera-icono {
  color: #2CE67C;
  width: 75px;  // También controla el tamaño con width
  height: 75px;  // Controla el tamaño con height
  position: relative;
}

.espera-texto {
  font-size: 1.4rem;
  position: relative;
  text-align: center;  // Centra el contenido del texto
}

.espera-bajada {
  font-size: 1.2rem;
  position: relative;
  text-align: center;  // Centra el contenido de la bajada
  width: 100%;  // Asegura que la bajada ocupe todo el ancho disponible para centrarse
}

.contenedor-blanco {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute; /* El hijo se posicionará respecto a su contenedor padre */
  top: 55%; /* Posicionarlo en el 50% de la altura del padre */
  left: 2%; /* Posicionarlo en el 50% del ancho del padre */
  width: 65%;
  height: 40%;
}

.div-people {
  display: flex;
  flex-direction: column;  // Asegura que los elementos se dispongan en columna
  align-items: center;  // Centra los elementos hijos horizontalmente
  gap: 3px;  // Espacio uniforme entre los elementos (ícono, texto y bajada)
  left: 3%;
  position: absolute;
  top: 10%; 
  color: white;
  width: 25%;
}

.people-icono {
  color: black;
  height: 85px;  
  width: 85px; 
  position: relative;
}

.people-numero {
  color: black;
  font-size: 2.5rem;
  font-weight: bolder;
  position: relative;
  text-align: center;  // Centra el contenido del texto
}

.people-texto {
  color: black;
  font-size: 1.4rem;
  position: relative;
  text-align: center;  // Centra el contenido del texto
}

.div-empresas {
  display: flex;
  align-items: center;  /* Centra los elementos verticalmente */
  justify-content: space-between;  /* Asegura que los elementos se distribuyan entre el ícono y el texto */
  position: absolute;
  left: 35%;
  top: 15%;
  width: 50%; 
}

.empresas-icono {
  color: black;
  height: 85px; 
  width: 85px; 
}

.empresas-texto {
  color: black;
  flex: 0 0 80%;  /* El texto ocupará el 80% del ancho del padre */
  font-size: 1.4rem;
  text-align: left;  /* Asegura que el texto esté alineado a la izquierda */
  padding-left: 10px;  /* Añade un pequeño margen entre el ícono y el texto */
}

.div-empresas-destacadas {
  display: flex;
  align-items: center;  /* Centra los elementos verticalmente */
  justify-content: space-between;  /* Asegura que los elementos se distribuyan entre el ícono y el texto */
  position: absolute;
  left: 35%;
  top: 55%;
  width: 50%;  /* El div padre ocupará el 40% */
}

.empresas-destacadas-icono {
  color: black;
  height: 85px; 
  width: 85px; 
}

.empresas-destacadas-texto {
  color: black;
  flex: 0 0 80%;  /* El texto ocupará el 80% del 40% */
  font-size: 1.4rem;
  text-align: left;
  padding-left: 10px;  /* Añade un pequeño margen entre el ícono y el texto */
}

.linea-vertical {
  background-color: black;
  height: 80%; /* Ajusta la altura según lo necesites */
  left: 30%;
  position: relative;
  top: 10%;
  width: 1px; /* Ancho muy pequeño para la línea vertical */
}

.logo-final {
  left: 40%;
  position: absolute; /* Posicionamiento absoluto */
  top: 92%;
  width: 6%;
}

/* New style for the installation label */
.instalacion-label {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #ff0000; /* Red background to match the table's "INSTALACIÓN" color */
  color: #ffffff; /* White text for contrast */
  padding: 5px 10px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  z-index: 3; /* Ensure it stays above other elements */
}