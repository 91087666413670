.table-ventas {
  table-layout: fixed !important;
  width: 100% !important;
  margin-top: 5px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  th,
  td {
    padding: 0.3rem !important;
  }

  // Aumentar la altura de la fila del thead
  thead tr {
    height: 45px !important;
  }

  // Ajustar el padding y el tamaño de la letra de los th dentro del thead
  thead th {
    padding: 0.5rem !important;
    vertical-align: middle !important;
    font-size: 1.1rem !important; // Aumenta el tamaño de la letra (ajusta según necesites)
  }

  // Colores personalizados para las columnas del thead
  th.bg-success {
    background-color: #28a745 !important; // Verde
    color: #fff !important;
  }

  th.bg-danger {
    background-color: #dc3545 !important; // Rojo
    color: #fff !important;
  }

  th.bg-primary {
    background-color: #007bff !important; // Azul
    color: #fff !important;
  }

  th.bg-warning {
    background-color: #ffc107 !important; // Amarillo
    color: #fff !important;
  }

  th.bg-info {
    background-color: #17a2b8 !important; // Celeste
    color: #fff !important;
  }
}