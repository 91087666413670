.automotriz-table {
  width: 80% !important; // Ancho del 80% del contenedor padre
  margin-top: 50px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: none !important; // Evita que un max-width del contenedor padre interfiera
}

.table-container {
  width: 70% !important; // Asegura que ocupe todo el ancho de .smartphones-table
  max-width: none !important; // Evita restricciones de ancho
  display: block; // Asegura que el contenedor sea un bloque
  margin: auto;
}
// Contenedor específico para la tabla del inventario
.inventory-container {
  width: 70% !important; // Asegura que ocupe todo el ancho de .smartphones-table
  max-width: none !important; // Evita restricciones de ancho
  display: block; // Asegura que el contenedor sea un bloque
  margin: auto;
}