.CalculadorComisionEdificio {
  width: 85%;
  margin: 2rem auto 0 auto;

  th[rowSpan="2"] {
    vertical-align: middle;
  }

  .rango-fecha {
    label {
      text-align: left;
      white-space: nowrap;
    }
    .react-daterange-picker__wrapper {
      border: none;
      gap: 5px;
    }
    .react-daterange-picker__inputGroup {
      flex-grow: initial;
      font-size: 1rem;
      min-width: auto;
      padding: 0;

      & > input:last-child {
        width: 35px !important;
      }
    }
    .react-daterange-picker__clear-button {
      display: none;
    }
    .react-daterange-picker__calendar-button {
      display: none;
    }
    .react-daterange-picker__inputGroup__input {
      padding: 0;
    }
  }

  .table-custom {
    &.table-totales {
      margin-bottom: 1rem;
      border-spacing: 0;
      table-layout: fixed;
      border-collapse: collapse;

      th, td {
        padding: 8px;
        box-sizing: border-box;
      }

      .total-row {
        font-weight: bold;
        border-bottom: 10px solid white;
      }

      /* Asegura anchos para la tabla de totales */
      td:nth-child(1) {
        width: 30% !important;
        min-width: 150px;
      }
      td:nth-child(2) {
        width: 20% !important;
        min-width: 120px;
      }
      td:nth-child(3) {
        width: 20% !important;
        min-width: 120px;
      }
      td:nth-child(4) {
        width: 20% !important;
        min-width: 120px;
      }
      td:nth-child(5) {
        width: 10% !important;
        min-width: 60px;
      }
    }

    &.table-detalles {
      margin-bottom: 50px;
      border-spacing: 0;
      table-layout: fixed;
      border-collapse: collapse;

      th, td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 8px;
        box-sizing: border-box;
      }

      /* Asegura anchos para la tabla de detalles */
      th:nth-child(1), td:nth-child(1) {
        width: 5% !important;
        min-width: 40px;
      }
      th:nth-child(2), td:nth-child(2) {
        width: 25% !important;
        min-width: 150px;
      }
      th:nth-child(3), td:nth-child(3) {
        width: 20% !important;
        min-width: 120px;
      }
      th:nth-child(4), td:nth-child(4) {
        width: 20% !important;
        min-width: 120px;
      }
      th:nth-child(5), td:nth-child(5) {
        width: 20% !important;
        min-width: 120px;
      }
      th:nth-child(6), td:nth-child(6) {
        width: 10% !important;
        min-width: 60px;
      }

      /* Estilo adicional para la tabla de detalles */
      th {
        background-color: #343a40;
        color: white;
        font-weight: bold;
      }

      tbody tr:nth-child(even) {
        background-color: #f8f9fa;
      }

      tbody tr:hover {
        background-color: #e9ecef;
      }
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
}