.ventas-mi-lista-clientes-table {
  table-layout: fixed !important;
  width: 100% !important;

  tr {
    height: 45px !important; // Altura fija para dos líneas
  }

  th, td {
    white-space: normal;
    word-wrap: break-word;
    vertical-align: middle;
    line-height: 1.5;
    font-size: 0.85em;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.2rem;
  }

  // Asegurar que bg-success se aplique correctamente
  th.bg-success {
    background-color: #198754 !important; // Color verde de Bootstrap
    color: #fff !important; // Texto blanco
  }

  .col-cliente { width: 18% !important; }
  .col-contacto { width: 18% !important; }
  .col-tipo { width: 5% !important; }
  .col-importes { width: 10% !important; }
  .col-7-5 { width: 10% !important; min-width: 120px !important; }
  .col-12 { width: 12% !important; }
  .col-8 { width: 8% !important; }
  .col-edit { width: 5% !important; }

  td[colspan="2"],
  th[colspan="2"] {
    width: 20% !important;
    min-width: 240px !important;
  }

  // Estilos generales para los campos de edición
  .small-input {
    width: 200px !important;
    font-size: 0.9em !important;
    text-align: start !important;
    padding: 2px !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
  }

  .edit-tipo .small-input {
    width: 40px !important;
  }

  .edit-ultimo-contacto .small-input {
    width: 100px !important;
    text-align: center !important;
  }

  .edit-proximo-contacto .small-input {
    width: 100px !important;
    text-align: center !important;
  }

  .contacto-status {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 4px;
  }

  .contacto-status.expired {
    background-color: #ffcccc !important;
    color: #800000 !important;
  }

  .contacto-status.todayTomorrow {
    background-color: #ffe6cc !important;
    color: #856404 !important;
  }

  .contacto-status.thisWeek {
    background-color: #fff3cd !important;
    color: #856404 !important;
  }

  .contacto-status.nextWeek {
    background-color: #cce5ff !important;
    color: #004085 !important;
  }

  .contacto-status.later {
    background-color: #d4edda !important;
    color: #155724 !important;
  }

  .contacto-status.default {
    background-color: transparent !important;
    color: inherit !important;
  }

  @media (max-width: 768px) {
    tr {
      height: 40px !important;
    }
    .col-7-5 { width: 12% !important; min-width: 100px !important; }
    td[colspan="2"], th[colspan="2"] { width: 24% !important; min-width: 200px !important; }
    th, td {
      font-size: 0.75em !important;
      padding: 0.1rem !important;
      line-height: 1.5 !important;
    }
    .small-input {
      width: 80px !important;
      font-size: 0.7em !important;
    }
    .edit-tipo .small-input {
      width: 60px !important;
    }
    .edit-ultimo-contacto .small-input,
    .edit-proximo-contacto .small-input {
      width: 90px !important;
    }
    .contacto-status { padding: 1px 4px; }
  }
}